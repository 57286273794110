import Vue from "vue";
import CardService from "@/services/CardService";
import PaymentService from "@/services/PaymentService";
import PersonService from "@/services/PersonService";
import OrderService from "@/services/OrderService";
import zPayNewPaymentMethodForm from "@/components/zPayNewPaymentMethodForm";
export default {
  components: {
    zPayNewPaymentMethodForm
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    charge: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cardService: new CardService(),
      paymentService: new PaymentService(),
      personService: new PersonService(),
      orderService: new OrderService(),
      cards: [],
      selectedCards: [],
      debits: [],
      totalPayment: 0,
      paymentSuccess: false,
      personName: "",
      showLoading: true,
      acceptedMultiPayments: false,
      loadingPayButton: false,
      isCheckout: false,
      text: this.$t("PAYMENT_SOLICITATION.PAY_PROCESSING.PAYMENT_PROCESSING")
    };
  },
  async mounted() {
    await this.listCards();
    if (this.cards && this.cards[0] && this.cards[0].acceptedMultiPayments) {
      this.acceptedMultiPayments =
        this.cards[0].acceptedMultiPayments.length > 0;
    }
    this.showLoading = false;
    let itemsIds = [];
    this.charge.items.forEach(function(item) {
      itemsIds.push(item.id);
    });
    const items = await this.orderService.getBulkItems(itemsIds);
    items.forEach(item => {
      const percentLeft = item.percentLeft || 100;
      const itemValue = (item.amount * item.quantity * percentLeft) / 100;
      this.debits.push({
        name: item.description,
        price: itemValue
      });
    });
    if (this.selectedCards.length === 0) {
      this.selectFirstByDefault();
    }
  },
  methods: {
    async Pay() {
      const personId = this.personService.getPersonId();
      let charge = this.charge;
      let chargesToPay = [];
      let cardInformation = [];
      const cardsToPay = this.cards.filter(card => {
        return (
          this.selectedCards.indexOf(
            `${card.firstSixDigits}-${card.lastFourDigits}`
          ) !== -1
        );
      });
      cardsToPay.forEach(card => {
        const newCharge = { ...charge };
        if (card.paymentValue && card.paymentValue > 0) {
          let operationType = "auth_and_capture";
          if (newCharge.metadata.type === "warranty_registration") {
            operationType = "auth_only";
          }

          newCharge.method = "creditCard";
          newCharge.creditCard = {
            cardId: card.gatewayCardId,
            operation: operationType,
            installments:
              card.acquirer.acceptInstallments &&
              newCharge.metadata.type !== "warranty_registration"
                ? card.paymentIterations.value || card.paymentIterations || 1
                : 1
          };

          newCharge.metadata = {
            chargeId: newCharge.id
          };
          newCharge.amount = card.paymentValue;
          chargesToPay.push(newCharge);
          cardInformation.push({
            lastFourDigits: card.lastFourDigits,
            brand: card.brand,
            installments: newCharge.creditCard.installments
          });
        }
      });
      this.loadingPayButton = true;
      Vue.$globalEvent.$emit("changePayLoadingButton", true);
      try {
        const resp = await this.paymentService.pay(
          charge.order.id,
          charge.id,
          personId,
          chargesToPay
        );
        this.loadingPayButton = false;
        Vue.$globalEvent.$emit("changePayLoadingButton", false);
        if (resp) {
          if (resp.status === 422) {
            Vue.$globalEvent.$emit("reload", true);
            return;
          }

          this.personName = this.personService.getPersonName();
          if (charge.metadata.type === "checkout") {
            this.isCheckout = true;
            this.text = this.$t("PAYMENT_SOLICITATION.PAY_PROCESSING.CHECKOUT");
          }
          this.paymentSuccess = true;
          this.$emit("successPayment");
        } else {
          this.$toaster.error(this.$t("GLOBAL.ERROR"));
        }
      } catch (err) {
        if (err.body && err.body.message) {
          this.$toaster.error(err.body.message);
        } else {
          this.$toaster.error(this.$t("GLOBAL.ERROR"));
        }
      }
    },
    backToAuth() {
      const token = this.paymentService.getChargeToken();
      if (token) {
        this.$router.push(`/auth?chargeToken=${token}`);
      } else {
        this.$router.push("/auth");
      }
    },
    cardChecked(value) {
      if (value) {
        this.selectedCards = [value];
      }
      const totalAmountInHundredths = Math.round(this.value * 100);
      const restInHundredths =
        totalAmountInHundredths % this.selectedCards.length;
      const totalAmountWithoutRest =
        (totalAmountInHundredths - restInHundredths) / 100;
      const rest = restInHundredths / 100;
      let paymentValue = totalAmountWithoutRest / this.selectedCards.length;
      let self = this;
      self.totalPayment = 0;
      let firstCardSelected = null;
      this.cards.forEach((card, index) => {
        card.acquirer.acceptInstallments =
          card.acquirer.acceptInstallments &&
          self.charge.metadata.type !== "warranty_registration";

        if (
          self.selectedCards.includes(
            `${card.firstSixDigits}-${card.lastFourDigits}`
          )
        ) {
          if (firstCardSelected === null) {
            firstCardSelected = index;
            card.paymentValue = paymentValue + rest;
          } else {
            card.paymentValue = paymentValue;
          }
          self.totalPayment =
            (self.totalPayment * 100 + card.paymentValue * 100) / 100;
        }
        card.iterationOptions = self.updateIterationsOptions(card);
        card.paymentIterations = card.iterationOptions[0];
      });
    },
    changeCardValue(card) {
      card.iterationOptions = this.updateIterationsOptions(card);
      card.paymentIterations = card.iterationOptions[0];

      let self = this;
      let changedCard = card;
      self.totalPayment = 0;

      this.cards.forEach(function(card) {
        if (self.selectedCards.length === 2) {
          if (
            `${card.firstSixDigits}-${card.lastFourDigits}` !==
              `${changedCard.firstSixDigits}-${changedCard.lastFourDigits}` &&
            self.selectedCards.includes(
              `${card.firstSixDigits}-${card.lastFourDigits}`
            ) &&
            changedCard.paymentValue < self.value &&
            changedCard.paymentValue > 0
          ) {
            card.paymentValue = self.value - changedCard.paymentValue;
            card.iterationOptions = self.updateIterationsOptions(card);
            card.paymentIterations = card.iterationOptions[0];
          }
        }
      });

      this.cards.forEach(function(card) {
        if (
          self.selectedCards.includes(
            `${card.firstSixDigits}-${card.lastFourDigits}`
          )
        )
          self.totalPayment =
            (self.totalPayment * 100 + card.paymentValue * 100) / 100;
      });

      this.$forceUpdate();
    },
    formatCurrency(amount) {
      if (isNaN(amount) || amount === "") {
        return "";
      } else {
        return amount.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        });
      }
    },
    async confirmDelete(cardId) {
      this.showLoading = true;
      await this.cardService.deleteCard(cardId).then(async () => {
        await this.listCards();
        this.selectFirstByDefault();
      });
    },
    async listCards() {
      this.showLoading = true;
      const cardsData = await this.cardService.getCards().catch(() => {});
      this.cards = cardsData.map(c => {
        const newCard = c;
        newCard.acquirer.acceptInstallments =
          c.acquirer.acceptInstallments &&
          this.charge.metadata.type !== "warranty_registration";

        return newCard;
      });
      this.showLoading = false;
    },
    installmentFeeText(currentInstallment, limitInstallment) {
      if (currentInstallment >= limitInstallment) {
        return ` / ${this.$t("PAYMENT_SOLICITATION.WITH_FEE")}`;
      } else {
        return ` / ${this.$t("PAYMENT_SOLICITATION.NO_FEE")}`;
      }
    },
    calcAmountWithFee(currentInstallment, limitInstallment, fee, paymentValue) {
      if (currentInstallment >= limitInstallment) {
        const calcFee = (1 + fee / 100) ** currentInstallment;
        return Math.round(paymentValue * calcFee * 100) / 100;
      } else {
        return paymentValue;
      }
    },
    updateIterationsOptions(card) {
      const iterationOptions = [];
      for (let i = 1; i <= card.acquirer.maxInstallments; i++) {
        let installmentFeeText;
        let amountWithFee;
        const limitInstallment = card.acquirer.installmentsFee.find(
          ({ fee }) => fee !== 0
        );
        if (limitInstallment) {
          installmentFeeText = this.installmentFeeText(
            i,
            limitInstallment.installment
          );
          amountWithFee =
            Math.round(
              (this.calcAmountWithFee(
                i,
                limitInstallment.installment,
                limitInstallment.fee,
                card.paymentValue
              ) *
                100) /
                i
            ) / 100;
        } else {
          installmentFeeText = ` / ${this.$t("PAYMENT_SOLICITATION.NO_FEE")}`;
          amountWithFee = Math.round((card.paymentValue * 100) / i) / 100;
        }
        const formatedAmountWithFee = this.formatCurrency(amountWithFee);
        if (
          i === 1 ||
          Math.round(card.paymentValue * 100) / i >=
            Math.round(card.acquirer.minPerInstallment * 100)
        ) {
          iterationOptions.push({
            text: `${i} x ${formatedAmountWithFee}${installmentFeeText}`,
            value: i
          });
        }
      }

      return iterationOptions;
    },
    selectFirstByDefault() {
      if (this.cards.length > 0) {
        if (this.acceptedMultiPayments) {
          var checkBox = document.querySelector(
            "#checkBox div div div div input"
          );
          checkBox.click();
        } else {
          var radio = document.querySelector("#radio div div input");
          radio.click();
        }
      }
    }
  }
};
