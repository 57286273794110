import zPayPaymentCheckbox from "../zPayPaymentCheckbox";
import zPayTxtFieldPaymentAmount from "../zPayTxtFieldPaymentAmount";
import zPayDeleteCardConfirmModal from "@/components/zPayDeleteCardConfirmModal";
export default {
  name: "zPayNewPaymentMethodForm",
  components: {
    zPayPaymentCheckbox,
    zPayTxtFieldPaymentAmount,
    zPayDeleteCardConfirmModal
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    cards: {
      type: Array,
      default: () => []
    },
    selectedCards: {
      type: Array,
      default: () => []
    },
    totalPayment: {
      type: Number,
      default: 0
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    acceptedMultiPayments: {
      type: Boolean,
      default: false
    },
    debits: {
      type: Array,
      default: []
    },
    loadingPayButton: {
      type: Boolean,
      default: false
    },
    charge: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      dialogExpenseVisible: false,
      loadingButton: false,
      deleteClicked: false,
      key: false,
      selectedCard: null,
      selectedCardsInternal: []
    };
  },
  mounted() {
    this.selectedCardsInternal = { ...this.selectedCards };
  },
  computed: {
    enablePayButton() {
      return (
        this.selectedCards.length > 0 &&
        this.totalPayment === this.value &&
        this.cards.length > 0
      );
    }
  },
  methods: {
    checkAmountPerInstallment() {
      const $context = this;
      const invalidAmountPerInstallment = this.cards.find(c => {
        return (
          $context.selectedCards.length > 1 &&
          $context.selectedCards.includes(
            `${c.firstSixDigits}-${c.lastFourDigits}`
          ) &&
          c.paymentValue < c.acquirer.minPerInstallment
        );
      });
      return invalidAmountPerInstallment === undefined;
    },
    openExpense() {
      this.dialogExpenseVisible = true;
    },
    confirmDelete(cardId) {
      this.$emit("confirmDelete", cardId);
      this.selectedCard = null;
      this.selectedCardsInternal = [];
      this.deleteClicked = false;
    },
    deleteCard(card) {
      this.key = !this.key;
      this.selectedCard = card;
      this.deleteClicked = true;
    },
    CloseExpense() {
      this.dialogExpenseVisible = false;
    },
    callCardChecked() {
      this.$emit("card-checked");
    },
    callCardSelected(value) {
      this.$emit("card-checked", value);
    },
    callChangeCardValue(card) {
      this.$emit("change-card-value", card);
      this.$forceUpdate();
    },
    callPay() {
      if (this.checkAmountPerInstallment()) {
        this.$emit("pay");
      }
    },
    getFlag: function(brand) {
      const flag = brand || "";
      return require(`@/assets/images/${flag.toLowerCase()}.png`);
    },

    allowsInstallmentPayment: function(card) {
      if (this.charge.metadata.allowInstallments === false) {
        return false;
      }

      let allowed = false;
      if (card.acquirer && card.acquirer.acceptInstallments) {
        allowed = true;
      }

      return allowed;
    }
  }
};
