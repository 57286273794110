export default {
  name: "zPayDeleteCardConfirmModal",
  props: {
    card: {
      type: Object,
      default: () => {
        null;
      }
    }
  },
  methods: {
    confirmDelete(cardId) {
      this.$emit("delete", cardId);
    }
  }
};
