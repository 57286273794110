import { zPayPinForm } from "@zoox-framework/zoox-components";
import CompanyService from "@/services/CompanyService";
import CardService from "@/services/CardService";
import OrderService from "@/services/OrderService";
import PaymentHeader from "../../../../components/PaymentHeader/PaymentHeader.vue";
import Create from "../../../views/Card/Create/Create.vue";
import PaymentService from "@/services/PaymentService";
import PersonService from "@/services/PersonService";
import PaymentMethod from "@/components/PaymentMethod/PaymentMethod.vue";
import PaymentCanceled from "@/components/PaymentCanceled/PaymentCanceled.vue";
import moment from "moment";
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  name: "PaymentSolicitations",
  components: {
    "z-pay-pin-form": zPayPinForm,
    PaymentMethod: PaymentMethod,
    PaymentCanceled: PaymentCanceled,
    PaymentHeader,
    Create
  },
  data() {
    return {
      personService: new PersonService(),
      companyService: new CompanyService(),
      orderService: new OrderService(),
      paymentService: new PaymentService(),
      cardService: new CardService(),
      company: {},
      order: {},
      debits: [],
      value: 0,
      charges: [],
      step: -1,
      chargeId: "",
      solicitations: true,
      pin: false,
      paymentMethod: false,
      paymentCanceled: false,
      paymentValue: 0,
      currency: "BRL",
      checkinDate: "",
      checkoutDate: "",
      currentCharge: {},
      shouldShowMessage: false,
      loadingPayment: false
    };
  },
  async created() {
    const orderService = new OrderService();
    const paymentService = new PaymentService();
    const personService = new PersonService();
    const personId = personService.getPersonId();
    const person = await this.personService.get(personId);
    this.order = await orderService.getLocalOrder();
    let charges = await orderService.getCharges(this.order.id, {
      status: "pending"
    });
    this.charges = [];
    if (charges && charges.length > 0) {
      this.charges.push(charges[0]);
    }
    if (this.order != null) {
      if (this.charges && this.charges.length > 0) {
        localStorage.removeItem("charge_token");
        localStorage.removeItem("order");
        await this.paymentService.setChargeToken(this.charges[0].token);
        this.order = await this.orderService.getOrderById(
          this.charges[0].order.id
        );
        await paymentService.updatePersonCharge(
          personId,
          this.charges[0].token,
          person.name
        );
        this.currentCharge = await this.orderService.getChargeByToken(
          this.charges[0].token
        );
        await this.orderService.setLocalOrder(this.order);
        this.paymentValue = this.currentCharge.amount;
        this.shouldShowMessage = false;
      }
    }
  },
  async mounted() {
    const token = await this.paymentService.getChargeToken();
    const orderService = new OrderService();
    if (token && token != "null") {
      this.currentCharge = await orderService.getChargeByToken(token);
    }
    this.step++;
    Vue.$globalEvent.$on("reload", async (reloadCharges = false) => {
      if (reloadCharges) {
        this.charges = await this.orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
      }
      this.showLoading = false;
      this.solicitations = true;
      this.pin = false;
      this.paymentMethod = false;
      this.paymentCanceled = false;
      this.loadingPayment = false;
      this.$store.dispatch("$setIsValidated", false);
    });
    const $context = this;
    if ($context.$store.state.isValidated === true) {
      this.solicitations = false;
      this.pin = false;
      this.paymentMethod = true;
      this.currentCharge = $context.$store.state.currentCharge;
      this.paymentValue = $context.$store.state.paymentValue;
    } else {
      this.company = this.companyService.getCurrentCompany();
      this.order = await orderService.getLocalOrder();
      if (this.order != null) {
        this.order.orderId = this.order.id || this.order._id;
        this.currency = this.order.currency || this.currency;
        this.charges = await orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
        let itemsIds = [];
        this.charges.forEach(charge => {
          charge.items.forEach(function(item) {
            itemsIds.push(item.id);
          });
        });
        const items = await this.orderService.getBulkItems(itemsIds);
        items.forEach(item => {
          const percentLeft = item.percentLeft || 100;
          const itemValue = (item.amount * item.quantity * percentLeft) / 100;
          this.debits.push({
            name: item.description,
            price: itemValue
          });
        });
        this.checkinDate = moment
          .utc(this.order.metadata.checkinDate)
          .endOf("day")
          .format(this.$t("GLOBAL.DATE_FORMAT"));
        this.checkoutDate = moment
          .utc(this.order.metadata.checkoutDate)
          .endOf("day")
          .format(this.$t("GLOBAL.DATE_FORMAT"));
        if (this.charges.length === 0) {
          this.shouldShowMessage = true;
        }
      } else {
        this.shouldShowMessage = true;
      }
    }
  },
  methods: {
    ...mapActions(["$setCurrentCharge", "$setIsValidated"]),
    openViewStatement(obj) {
      this.value = obj.amount;
      this.debits = obj.debits;
      this.dialogExpenseVisible = true;
    },
    getGeolocation() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(({ coords }) =>
            resolve(coords)
          );
        } else {
          reject("Geolocation services are not supported by your browser");
        }
      });
    },

    successPayment() {
      const $context = this;
      $context.$setCurrentCharge(this.currentCharge);
      $context.$setIsValidated(true);
      $context.$router.push("/payment/checkout");
    },

    async Pay(id, value, charge) {
      this.loadingPayment = true;
      const updatedCharge = await this.orderService.getChargeByToken(
        charge.token
      );

      if (updatedCharge.status === "pending") {
        this.paymentService.generatePin(this.order.orderId, id);
        this.chargeId = id;
        this.paymentValue = value;
        this.currentCharge = charge;
        this.solicitations = false;
        this.pin = true;
      } else {
        this.paymentCanceled = true;
      }
    },
    ValidatePin(validated) {
      const $context = this;
      if (validated === true) {
        $context.$store.dispatch("$setCurrentCharge", this.currentCharge);
        $context.$store.dispatch("$setPaymentValue", this.paymentValue);
        $context.$store.dispatch("$setIsValidated", true);
        this.pin = false;
        this.paymentMethod = true;
      } else {
        this.$toaster.error(this.$t("PAYMENT_SOLICITATION.PIN_NOT_VALID"));
      }
    },
    async submitPin({ orderId, pinNumber, chargeId }) {
      const resp = await this.paymentService.validatePin(
        orderId,
        pinNumber,
        chargeId
      );
      this.ValidatePin(resp);
    },
    async forgotPin({ orderId, chargeId }) {
      await this.paymentService.generatePin(orderId, chargeId);
      this.$toaster.success(this.$t("PAYMENT_SOLICITATION.PIN_GENERATED"));
    }
  }
};
