export default {
  name: "zPayPaymentCheckbox",
  props: ["checked", "value", "acceptedMultiPayments"],
  model: {
    prop: "checked"
  },
  data() {
    return {
      checkbox: false
    };
  },
  watch: {
    checkbox(newValue) {
      if (newValue) {
        this.checked.push(this.value);
      } else {
        this.checked.splice(this.checked.indexOf(this.value), 1);
      }
      this.$emit("check");
    }
  },
  computed: {
    disableCheckbox() {
      let reachedTheLimit = false;
      if (this.acceptedMultiPayments) {
        reachedTheLimit = this.checked.length > 1;
      } else {
        reachedTheLimit = this.checked.length > 0;
      }
      return !this.checkbox && reachedTheLimit;
    }
  }
};
