import { validationMixin } from "@zoox-framework/smck-service";

export default {
  name: "zPayTxtFieldPaymentAmount",
  mixins: [validationMixin],
  props: ["card", "value"],
  data() {
    return {
      amount: this.card.paymentValue
    };
  },
  watch: {
    $props: {
      handler() {
        this.amount = this.value;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    updatedAmount() {
      this.card.paymentValue = this.amount;
      this.$emit("change", this.card);
    }
  },
  computed: {
    ruleAmount() {
      let rules = [];
      if (this.card.acquirer && this.card.acquirer.minPerInstallment > 0) {
        rules = [
          val => this.rules.required(val),
          () => {
            const minPerInstallment = this.card.acquirer.minPerInstallment;
            let valid = this.rules.minValue(this.amount, minPerInstallment);
            if (valid !== true) {
              valid = `${valid}: ${minPerInstallment.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`;
            }
            return valid;
          }
        ];
      } else {
        rules = [val => this.rules.required(val)];
      }
      return rules;
    },
    amountFormatted: {
      get: function() {
        if (isNaN(this.card.paymentValue) || this.amount === "") {
          this.amount = 0;
          return "0";
        }
        const ret = this.amount.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return ret;
      },
      set: function(modifiedValue) {
        // eslint-disable-next-line
        modifiedValue = modifiedValue
          .replace(/\D/g, '');

        if (modifiedValue.length > 2) {
          var last2 = modifiedValue.slice(-2);
          modifiedValue =
            modifiedValue.substring(0, modifiedValue.length - 2) + "," + last2;
        } else if (modifiedValue.length === 2) {
          modifiedValue = "0," + modifiedValue;
        } else if (modifiedValue.length === 1) {
          modifiedValue = "0,0" + modifiedValue;
        }

        const parsedAmount = parseFloat(modifiedValue.replace(",", "."));
        this.amount = isNaN(parsedAmount) ? 0 : parsedAmount;
      }
    }
  }
};
